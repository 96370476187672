<template>
  <div class="blank_asset3">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">지급처정보</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 171px" />
          <col />
          <col style="width: 171px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>지급처</th>
            <td>
              <div class="row_pack">
                <Input
                  placeholder="지급처를 입력하세요"
                  :value.sync="certificateItemData.partnerName"
                  :maxLength="100"
                  @focusout="onFocusout"
                />
                <button
                  type="button"
                  class="btn_small btn_fourthly"
                  @click="$emit('onClickPopPartner')"
                >
                  조회
                </button>
              </div>
            </td>
            <th>사업자/주민등록번호</th>
            <td>
              <Input
                :value.sync="certificateItemData.corporateNum"
                :maxLength="100"
                @focusout="onFocusout"
              />
            </td>
          </tr>
          <tr>
            <th>사업자/주민등록번호 사본</th>
            <td colspan="3">
              <FileView :dataList="businessRegFile" />
            </td>
          </tr>
          <tr>
            <th>통장 사본</th>
            <td colspan="3">
              <FileView :dataList="[certificateItemData.bankBookFile]" />
            </td>
          </tr>
          <tr>
            <th>세금계산서/계산서</th>
            <td colspan="3">
              <FileView :dataList.sync="certificateItemData.billFiles" :isComments="true" />
            </td>
          </tr>
          <tr>
            <th>기타 증빙 문서</th>
            <td colspan="3">
              <FileView :dataList.sync="certificateItemData.etcFiles" :isComments="true" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Input from "@/_approval/components/common/input/Input";
import FileWrite from "@/_approval/components/common/file/FileWrite";
import StatementFileWrite from "@/_approval/components/common/file/StatementFileWrite";
import StatementFileListWrite from "@/_approval/components/common/file/StatementFileListWrite";
import FileView from "@/_approval/components/common/file/FileView";

export default {
  name: "StatmentPartner",
  components: {
    Input,
    FileWrite,
    StatementFileWrite,
    StatementFileListWrite,
    FileView,
  },
  props: {
    certificateItemData: Object,
  },
  computed: {
    businessRegFile() {
      const fileList = [];

      if (this.certificateItemData.businessRegFile.attachNum) {
        fileList.push(this.certificateItemData.businessRegFile);
      }

      return fileList;
    },
    bankBookFile() {
      const fileList = [];

      if (this.certificateItemData.bankBookFile.attachNum) {
        fileList.push(this.certificateItemData.bankBookFile);
      }

      return fileList;
    },
    billFile() {
      const fileList = [];

      if (this.certificateItemData.billFiles.attachFile.attachNum) {
        fileList.push(this.certificateItemData.bankBookFile);
      }

      return fileList;
    },
    etcFile() {
      const fileList = [];

      if (this.certificateItemData.etcFiles.attachFile.attachNum) {
        fileList.push(this.certificateItemData.bankBookFile);
      }

      return fileList;
    },
  },
  methods: {
    isEmpty(dataList) {
      return dataList && dataList.length == 0;
    },
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    isEmptyFile(data) {
      const fileList = [];

      if (this.certificateItemData.bankBookFile.attachNum) {
      }
    },
    getListAfterRemove(fileToRemove, dataList) {
      const ret = dataList.filter((item) => item.attachNum !== fileToRemove.attachNum);

      return ret;
    },
    uploadBillFiles(data) {
      const obj = {};
      obj.attachFile = data;
      obj.attachId = "";
      obj.comments = "";
      this.certificateItemData.billFiles.push(obj);
    },
    removeBillFiles(idx, data) {
      this.certificateItemData.billFiles.splice(idx, 1);
    },
    uploadEtcFiles(data) {
      const obj = {};
      obj.attachFile = data;
      obj.attachId = "";
      obj.comments = "";
      this.certificateItemData.etcFiles.push(obj);
    },
    removeEtcFiles(idx, data) {
      this.certificateItemData.etcFiles.splice(idx, 1);
    },
  },
};
</script>
